<template>
  <div class="dealers">
    <page-subnav class="dealers__subnav">
      <ui-button
        class="dealers__create-btn"
        @click="isDealerCreateModalShown = true"
      >
        {{ $t('CREATE_DEALER_BTN') }}
        <ui-icon icon="plus" />
      </ui-button>

      <dealers-list-params
        class="dealers__subnav-action"
        :value="{
          copartLogin: $route.query.copartLogin || ''
        }"
        @input="onListParamsChange"
      />

      <subnav-search
        class="dealers__subnav-action dealers__subnav-action_ml"
        :value="$route.query.search"
        @input="onSearch"
      />
    </page-subnav>

    <dealers-list
      class="dealers__list"
      :list="list"
      :load-more="loadMore"
      @update-list-ask="loadList"
      @more-ask="loadMore"
    />

    <dealer-card-modal
      class="dealers__item-card-modal"
      :dealers-list="list"
      :is-dealers-loading="isLoading"
      :dealer-id="$route.params.dealerId"
      @update:dealerId="$router.push({
        params: { dealerId: $event },
        query: $route.query,
      })"
      @close="$router.push({
        params: { dealerId: null },
        query: $route.query,
      })"
    />

    <dealer-create-modal
      v-if="isDealerCreateModalShown"
      class="dealers__create-modal"
      @close="isDealerCreateModalShown = false"
    />
  </div>
</template>

<script>
import { UiButton, UiIcon } from '@shelf.network/ui-kit'
import PageSubnav from 'Common/PageSubnav'
import SubnavSearch from 'Common/SubnavSearch'

import DealersList from './components/DealersList'
import DealersListParams from './components/DealersListParams'
import DealerCreateModal from './components/DealerCreateModal'
import DealerCardModal from './components/DealerCardModal'

import { mapActions, mapMutations, mapGetters } from 'vuex'
import { extensionDealersActions, extensionDealersMutations, extensionDealersGetters } from './store/types'

import { showError } from 'Utils/notifications'

import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

export default {
  name: 'dealers',
  components: {
    PageSubnav,
    SubnavSearch,
    UiButton,
    UiIcon,
    DealersList,
    DealersListParams,
    DealerCreateModal,
    DealerCardModal,
  },

  data () {
    return {
      isDealerCreateModalShown: false,
    }
  },

  computed: {
    ...mapGetters('ui/extension-dealers', {
      list: extensionDealersGetters.LIST,
      isLoading: extensionDealersGetters.IS_LOADING,
    }),
  },

  async created () {
    await Promise.all([
      this.loadManagedLogins(),
      this.loadAccountsBlacklist()
    ])
    await this.loadList()
  },

  methods: {
    ...mapActions('ui/extension-dealers', {
      loadListAction: extensionDealersActions.LOAD_LIST,
      loadMore: extensionDealersActions.LOAD_MORE,
      loadManagedLogins: extensionDealersActions.LOAD_MANAGED_LOGINS,
      loadAccountsBlacklist: extensionDealersActions.LOAD_ACCOUNTS_BLACKLIST,
    }),

    ...mapMutations('ui/extension-dealers', {
      setIsLoading: extensionDealersMutations.SET_IS_LOADING,
    }),

    onListParamsChange (input) {
      const query = { ...this.$route.query }

      query.copartLogin = input.copartLogin || undefined

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }

      this.loadList()
    },

    onSearch (str) {
      str = str.trim()
      if ((this.$route.query.search || '') === str) return
      this.setIsLoading(true)
      this.applySearchDebounced(str)
    },

    applySearchDebounced: debounce(async function (search) {
      await this.applySearch(search)
    }, 1000),

    applySearch (str) {
      const query = { ...this.$route.query }
      if ((query.search || '') === str) return
      query.search = str || undefined

      this.$router.push({ query })
      this.loadList()
    },

    async loadList () {
      try {
        const payload = this.buildLoadListPayload()
        await this.loadListAction(payload)
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },

    buildLoadListPayload () {
      const result = { filter: {}, include: 'basics' }
      const query = this.$route.query || {}

      if (query.search) {
        result.filter.search = query.search || ''
      }

      if (query.copartLogin) {
        result.filter.copartLogin = query.copartLogin
      }

      return result
    },
  },

  metaInfo () {
    return {
      title: this.$t('META_TITLE'),
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.dealers {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>

<i18n>
{
  "en": {
    "CREATE_DEALER_BTN": "New",
    "META_TITLE": "Dealers",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the dealers list. Please try again later or contact the system owner."
  },
  "ka": {
    "CREATE_DEALER_BTN": "ახალი",
    "META_TITLE": "დილერი",
    "LIST_FETCH_FAILED_NOTIFY": "დილერი სია ვერ ჩაიტვირთა. მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "CREATE_DEALER_BTN": "Новый",
    "META_TITLE": "Дилеры",
    "LIST_FETCH_FAILED_NOTIFY": "Не удалось загрузить список дилеров. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "CREATE_DEALER_BTN": "Новий",
    "META_TITLE": "Дилери",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдалось завантажити список дилерів. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
