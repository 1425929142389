<template>
  <button
    class="dealers-export"
    :disabled="isLoading"
    @click="exportDealers"
  >
    <template v-if="isLoading">
      {{ $t('LOADING_MSG') }}
    </template>

    <template v-else>
      {{ $t('EXPORT_BTN') }}
    </template>
  </button>
</template>

<script>
import { downloadCsvFile } from 'Utils/downloadCsvFile'
import { showError } from 'Utils/notifications'

import { mapActions, mapGetters } from 'vuex'
import { extensionDealersActions, extensionDealersGetters } from '../store/types'

import { formatDate } from 'Utils/dateHelpers'
import { formatLocation } from 'Utils/formatLocation'

export default {
  name: 'dealers-export',
  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapGetters('ui/extension-dealers', {
      exportList: extensionDealersGetters.EXPORT_LIST,
      exportLocations: extensionDealersGetters.EXPORT_LOCATIONS
    }),

    dealersBlob () {
      const dealerRows = this.exportList.map(dealer => {
        return [
          dealer.fullName,
          dealer.email,
          dealer.copartLogin,
          formatLocation(this.exportLocations[dealer.location.id]),
          formatDate(dealer.createdAt, 'yyyy-MM-dd HH:mm:ss')
        ].join(',')
      })

      return [this.$t('EXPORT_TABLE_HEAD'), ...dealerRows].join('\n')
    }
  },

  methods: {
    ...mapActions('ui/extension-dealers', {
      loadExportList: extensionDealersActions.LOAD_EXPORT_LIST,
      loadExportLocations: extensionDealersActions.LOAD_EXPORT_LOCATIONS
    }),

    async exportDealers () {
      try {
        this.isLoading = true
        await this.loadExportList()
        await this.loadExportLocations()
        this.isLoading = false

        const filename = [
          formatDate(new Date(), 'yyyy-MM-dd'),
          'dealers'
        ].join('-')

        downloadCsvFile(this.dealersBlob, filename)
      } catch (e) {
        showError(this.$t('TOASTS.UNEXPECTED_ERROR'))
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dealers-export {
  background: none;
  border: none;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.5;
  color: $color-sys-info;
  transition: color 150ms;

  &:hover,
  &:disabled {
    color: rgba($color-sys-info, 0.8);
  }
}
</style>

<i18n>
{
  "en": {
    "LOADING_MSG": "Loading…",
    "EXPORT_BTN": "Export",
    "EXPORT_TABLE_HEAD": "Full Name,Email,Copart Login,Location,Creation Date"
  },
  "ka": {
    "LOADING_MSG": "იტვირთება…",
    "EXPORT_BTN": "გადმოწერა",
    "EXPORT_TABLE_HEAD": "სახელი და გვარი,ელ.ფოსტა,Copart-ზე შესვლა,ლოკაცია,შექმნის თარიღით"
  },
  "ru": {
    "LOADING_MSG": "Загрузка…",
    "EXPORT_BTN": "Экспорт",
    "EXPORT_TABLE_HEAD": "Имя,Email,Логин Copart,Локация,Дата создания"
  },
  "uk": {
    "LOADING_MSG": "Завантаження…",
    "EXPORT_BTN": "Експорт",
    "EXPORT_TABLE_HEAD": "Ім'я,Email,Логін Copart,Локація,Дата створення"
  }
}
</i18n>
