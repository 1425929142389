import { Command } from '@/commands/Command'
import { sdk } from 'Services/shelfNetworkSdk'

import { rootGet, rootDispatch } from 'Store/helpers/rootHelpers'
import { userGetters } from 'Store/entities/User/types'

import { extensionDealersActions } from '../store/types'
import { DEALERS_LIMIT_ERROR_MESSAGE } from '../constants'

import formatFullName from 'Utils/formatFullName'
import { safeUserName } from 'Utils/safeUserName'
import { showError } from 'Utils/notifications'

import get from 'lodash/get'

/**
 * @typedef {object} CreateDealerArgsDef
 * @property {object} attributes Attributes of the dealer to create
 *
 * @typedef {import('@/commands/Command').CommandArgs} CommandArgs
 * @typedef {CommandArgs & CreateDealerArgsDef} CreateDealerArgs
 */

export class CreateDealer extends Command {
  /**
   * Create a configured CreateDealer command
   * @param {CreateDealerArgs} opts
   */
  constructor (opts) {
    super(opts)

    this._attrs = opts.attributes
  }

  async execute () {
    try {
      const { password, copartLogin, ...attrs } = this._attrs
      const platformId = rootGet(`entities/user/${userGetters.PLATFORM_ID}`)
      attrs.platformId = attrs.platformId || platformId
      const { data } = await sdk.horizon.account.create(attrs, null, password)

      await sdk.copartBackOffice.updateIdentity({
        identityId: data.id,
        attributes: { copartLogin }
      })

      rootDispatch(
        `ui/extension-dealers/${extensionDealersActions.UNSHIFT_ITEM}`,
        this._convertUserEntryToIdentity(data)
      )

      const msg = this.$t('CMD.USERS.CREATE.SUCCESS_MSG', { name: this._attrs.firstName })
      this._reportSuccess(msg)
    } catch (error) {
      let msg
      const serverErrorMsg = get(error, 'meta.error')

      if (serverErrorMsg === DEALERS_LIMIT_ERROR_MESSAGE) {
        showError(this.$t('CMD.USERS.UPDATE.DEALERS_LIMIT_ERROR', {
          account: this._attrs.copartLogin,
        }))
        return
      }

      if (error.httpStatus === 400 && serverErrorMsg) {
        msg = this.$t('CMD.USERS.CREATE.REQUEST_ERROR_MSG', {
          name: this._attrs.firstName,
          error: serverErrorMsg
        })
      } else if (error.httpStatus === 409) {
        msg = this.$t('CMD.USERS.CREATE.FAILURE_EXISTS_MSG')
      } else {
        msg = this.$t('CMD.USERS.CREATE.FAILURE_MSG', {
          name: this._attrs.firstName
        })
      }

      this._reportError(error, msg)
    }
  }

  _convertUserEntryToIdentity (userEntry) {
    return {
      id: userEntry.id,
      relationships: {
        basic: {
          email: userEntry.email,
          copartLogin: this._attrs.copartLogin,
          fullName: safeUserName(formatFullName(userEntry))
        }
      }
    }
  }
}
