import { DealerCommand } from './DealerCommand'
import { sdk } from 'Services/shelfNetworkSdk'

import { rootDispatch } from 'Store/helpers/rootHelpers'
import { extensionDealersActions } from '../store/types'

export class BlockDealer extends DealerCommand {
  async execute () {
    try {
      const copartLogin = 'null'

      rootDispatch(
        `ui/extension-dealers/${extensionDealersActions.ADD_TO_BLACKLIST}`,
        this._dealerEntry.id
      )
      await sdk.copartBackOffice.updateIdentity({
        identityId: this._dealerEntry.id,
        attributes: { copartLogin }
      })

      this._updateStoredItem({
        relationships: { basic: { copartLogin } }
      })

      const msg = this.$t('CMD.USERS.BLOCK.SUCCESS_MSG', { name: this._getName() })
      this._reportSuccess(msg)
    } catch (error) {
      const msg = this.$t('CMD.USERS.BLOCK.FAILURE_MSG', { name: this._getName() })
      this._reportError(error, msg)
    }
  }
}
