<template>
  <p
    v-if="isHighFeeLevel"
    class="fee-level-badge"
  >
    {{ $t('HIGH_FEE_LEVEL_BADGE') }}
  </p>
</template>

<script>
import { FEE_LEVELS } from '../constants'

export default {
  name: 'fee-level-badge',

  props: {
    feeLevel: { type: String, required: true },
  },

  computed: {
    isHighFeeLevel () {
      return this.feeLevel === FEE_LEVELS.HIGH
    }
  }
}
</script>

<style lang="scss" scoped>
.fee-level-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-sys-info;
  color: $color-white;
  width: 16px;
  min-width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 1;
  border-radius: 50%;
}
</style>

<i18n>
{
  "en": {
    "HIGH_FEE_LEVEL_BADGE": "C"
  },
  "ka": {
    "HIGH_FEE_LEVEL_BADGE": "C"
  },
  "ru": {
    "HIGH_FEE_LEVEL_BADGE": "C"
  },
  "uk": {
    "HIGH_FEE_LEVEL_BADGE": "C"
  }
}
</i18n>
