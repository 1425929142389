<template>
  <div class="dealers-list-head dealers-cmn__row">
    <span class="dealers-list-head__cell">
      {{ $t('DEALER_HEAD_CELL') }}
    </span>

    <span class="dealers-list-head__cell">
      {{ $t('EMAIL_HEAD_CELL') }}
    </span>

    <span class="dealers-list-head__cell">
      {{ $t('COPART_LOGIN_HEAD_CELL') }}
    </span>

    <span class="dealers-list-head__cell">
      {{ $t('LOCATION_HEAD_CELL') }}
    </span>

    <span class="dealers-list-head__cell">
      {{ $t('FEE_HEAD_CELL') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'dealers-list-head',
}
</script>

<style lang="scss" scoped>
@import "../styles/dealers.scss";

.dealers-list-head {
  padding: 2em 3em 0;

  &__cell {
    @extend .dealers-cmn__cell;

    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "DEALER_HEAD_CELL": "DEALER",
    "EMAIL_HEAD_CELL": "EMAIL",
    "COPART_LOGIN_HEAD_CELL": "COPART LOGIN",
    "LOCATION_HEAD_CELL": "LOCATION",
    "FEE_HEAD_CELL": "FEE STRUCTURE"
  },
  "ka": {
    "DEALER_HEAD_CELL": "ᲓᲘᲚᲔᲠᲘ",
    "EMAIL_HEAD_CELL": "ᲔᲚ.ᲤᲝᲡᲢᲠᲐ",
    "COPART_LOGIN_HEAD_CELL": "COPART-ᲖᲔ ᲨᲔᲡᲕᲚᲐ",
    "LOCATION_HEAD_CELL": "ᲚᲝᲙᲐᲪᲘᲐ",
    "FEE_HEAD_CELL": "ᲓᲐᲠᲘᲪᲮᲕᲐ"
  },
  "ru": {
    "DEALER_HEAD_CELL": "ДИЛЕР",
    "EMAIL_HEAD_CELL": "ЭЛ. ПОЧТА",
    "COPART_LOGIN_HEAD_CELL": "ЛОГИН COPART",
    "LOCATION_HEAD_CELL": "ЛОКАЦИЯ",
    "FEE_HEAD_CELL": "КОМИССИЯ"
  },
  "uk": {
    "DEALER_HEAD_CELL": "ДИЛЕР",
    "EMAIL_HEAD_CELL": "ЕЛ. ПОШТА",
    "COPART_LOGIN_HEAD_CELL": "ЛОГІН COPART",
    "LOCATION_HEAD_CELL": "ЛОКАЦІЯ",
    "FEE_HEAD_CELL": "КОМІСІЯ"
  }
}
</i18n>
