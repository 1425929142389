<template>
  <div class="dealer-card-name-edit">
    <template v-if="!isEditing">
      <p class="dealer-card-name-edit__name-str">
        {{ fullName }}
      </p>

      <fee-level-badge
        class="dealer-card-name-edit__name-badge"
        :fee-level="identity.feeLevel"
      />

      <ui-button
        class="dealer-card-name-edit__inline-btn"
        frame="none"
        fill="none"
        @click="startEdit()"
      >
        <ui-icon
          class="dealer-card-name-edit__inline-btn-ico"
          icon="pen"
        />
      </ui-button>
    </template>

    <template v-else>
      <form
        class="dealer-card-name-edit__form"
        @submit.prevent="submit()"
        @reset.prevent="stopEdit()"
      >
        <ui-text
          v-model="form.firstName"
          class="dealer-card-name-edit__field"
          :placeholder="$t('FIRST_NAME_PH')"
          maxlength="30"
          :is-error="formErrors.firstName.length > 0"
          @input="formErrors.firstName = []"
        >
          <p
            class="dealer-card-name-edit__field-error"
            slot="error"
            v-for="(err, i) of formErrors.firstName"
            :key="i"
          >
            {{ jsvLocalizeError(err) }}
          </p>
        </ui-text>

        <ui-text
          v-model="form.lastName"
          class="dealer-card-name-edit__field"
          :placeholder="$t('LAST_NAME_PH')"
          maxlength="30"
          :is-error="formErrors.lastName.length > 0"
          @input="formErrors.lastName = []"
        >
          <p
            class="dealer-card-name-edit__field-error"
            slot="error"
            v-for="(err, i) of formErrors.lastName"
            :key="i"
          >
            {{ jsvLocalizeError(err) }}
          </p>
        </ui-text>

        <div class="dealer-card-name-edit__form-actions">
          <ui-button
            class="dealer-card-name-edit__inline-btn"
            frame="none"
            fill="none"
            type="submit"
          >
            <ui-icon
              class="dealer-card-name-edit__inline-btn-ico"
              icon="success"
            />
          </ui-button>

          <ui-button
            class="dealer-card-name-edit__inline-btn"
            frame="none"
            fill="none"
            type="reset"
          >
            <ui-icon
              class="dealer-card-name-edit__inline-btn-ico"
              icon="close"
            />
          </ui-button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { UiIcon, UiButton, UiText } from '@shelf.network/ui-kit'
import FeeLevelBadge from '../FeeLevelBadge'

import { UserEntry } from 'Models/UserEntry'
import { UpdateDealer } from '../../commands/UpdateDealer'

import { extensionDealersGetters } from '../../store/types'
import { mapGetters } from 'vuex'

import validationSchema from '../../json-schemas/update-name-validation.json'

import { jsv } from 'Utils/jsv'
import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

import { safeUserName } from 'Utils/safeUserName'
import formatFullName from 'Utils/formatFullName'

export default {
  name: 'dealer-card-name-edit',
  components: {
    UiIcon,
    UiButton,
    UiText,
    FeeLevelBadge
  },

  props: {
    dealerEntry: { type: UserEntry, required: true },
  },

  data () {
    return {
      isEditing: false,
      form: this.entryToForm(),
      formErrors: this.entryToFormErrorsDefault()
    }
  },

  computed: {
    ...mapGetters('ui/extension-dealers', {
      identity: extensionDealersGetters.IDENTITY,
    }),

    fullName () {
      return safeUserName(formatFullName(this.dealerEntry))
    },
  },

  created () {
    this.$watch(
      () => [
        this.dealerEntry.firstName,
        this.dealerEntry.lastName,
      ],
      () => { this.form = this.entryToForm() },
    )
  },

  methods: {
    entryToForm () {
      return {
        firstName: this.dealerEntry.firstName,
        lastName: this.dealerEntry.lastName,
      }
    },

    entryToFormErrorsDefault () {
      const newObjEntries = Object.entries(this.entryToForm())
        .map(([key]) => [key, []])
      return Object.fromEntries(newObjEntries)
    },

    checkFormValid () {
      if (Object.values(this.formErrors).some(el => el.length > 0)) return false

      const result = jsv.validate(validationSchema, this.form)
      if (result.isValid) return true

      Object.assign(this.formErrors, result.byField())
      return false
    },

    async submit () {
      if (!this.checkFormValid()) return

      const cmd = new UpdateDealer({
        dealerEntry: this.dealerEntry,
        attributes: {
          relationships: {
            basic: {
              ...this.form,
              fullName: formatFullName(this.form)
            }
          }
        },
      })
      await cmd.execute()
      this.stopEdit()
    },

    startEdit () {
      this.form = this.entryToForm()
      this.isEditing = true
    },

    stopEdit () {
      this.isEditing = false
    },

    jsvLocalizeError,
  }
}
</script>

<style lang="scss" scoped>
.dealer-card-name-edit {
  display: flex;
  align-items: center;
  line-height: 2em;

  &__name {
    &-str {
      @include respond(--font-size, 1.4em, 1.4em, 1.3em, 1.2em);

      font-size: var(--font-size);
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-badge {
      margin-left: 0.4em;
    }
  }

  &__inline-btn {
    line-height: 1;
    margin-left: 0.5em;
    justify-self: start;

    /deep/ .ui-button__button {
      padding: 0.25em;
    }

    &-ico {
      display: block;
      font-size: 1.5em;
    }
  }

  &__field {
    font-size: 0.9em;

    /deep/ .ui-text__input {
      font-size: inherit;

      &[fill][look] {
        background-color: $color-light;
        border-color: $color-grey;

        &:focus {
          border-color: $color-ui-default;
        }
      }
    }
  }

  &__form {
    display: grid;
    align-items: start;
    width: 100%;
    margin-bottom: 1em;
    gap: 0.5em;
    grid: auto / repeat(auto-fit, minmax(auto, 14em));

    &-actions {
      display: grid;
      justify-content: start;
      align-items: center;
      grid: auto / auto-flow auto;
      height: 2.8em;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "FIRST_NAME_PH": "First name",
    "LAST_NAME_PH": "Last name"
  },
  "ka": {
    "FIRST_NAME_PH": "სახელი",
    "LAST_NAME_PH": "გვარი"
  },
  "ru": {
    "FIRST_NAME_PH": "Имя",
    "LAST_NAME_PH": "Фамилия"
  },
  "uk": {
    "FIRST_NAME_PH": "Ім’я",
    "LAST_NAME_PH": "Прізвище"
  }
}
</i18n>
