<template>
  <div class="dealer-cell-name-ava cells-cmn__cell">
    <lazy-ava-img
      class="cells-cmn__ava"
      :alt="identity.fullName"
      :title="identity.fullName"
      :src="identity.avatarLink"
    />

    <router-link
      :to="{ params: { dealerId: identity.id }, query: $route.query }"
      class="cells-cmn__btn dealer-cell-name-ava__link"
    >
      <span
        class="cells-cmn__str cells-cmn__str_nb dealer-cell-name-ava__link-txt"
        :title="identity.fullName"
      >
        {{ identity.fullName }}
      </span>

      <fee-level-badge
        class="dealer-cell-name-ava__link-badge"
        :fee-level="identity.feeLevel"
      />
    </router-link>
  </div>
</template>

<script>
import LazyAvaImg from 'Common/LazyAvaImg'
import FeeLevelBadge from './FeeLevelBadge'
import { Identity } from 'Models/Identity'

export default {
  name: 'dealer-cell-name-ava',
  components: {
    LazyAvaImg,
    FeeLevelBadge,
  },

  props: {
    identity: { type: Identity, required: true },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";

.dealer-cell-name-ava {
  &__link {
    text-decoration: none;
    display: flex;
    align-items: center;

    &-txt {
      line-height: 1;
    }

    &-badge {
      margin-left: 0.4em;
    }
  }
}
</style>
