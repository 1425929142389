<template>
  <div class="dealer-item">
    <div class="dealers-cmn__row">
      <dealer-cell-name-ava
        class="dealer-item__cell"
        :identity="item"
      />

      <dealer-cell-email
        class="dealer-item__cell"
        :email="item.email"
      />

      <p class="dealer-item__cell">
        <component
          :is="isAccountLinkShown ? 'router-link' : 'span'"
          :to="{
            name: 'extension.copart.accounts.entry',
            query: { search: item.copartLogin }
          }"
          class="dealers-cmn__str dealers-cmn__str_sec"
          :class="{
            'dealers-cmn__str_err': isBlocked,
            'dealers-cmn__str_link': isAccountLinkShown
          }"
        >
          <template v-if="isBlocked">
            {{ $t('BLOCKED_STATUS_MSG') }}
          </template>

          <template v-else-if="isAssigned">
            {{ item.copartLogin }}
          </template>

          <template v-else>
            {{ $t('COMMON.NOT_ASSIGNED_MSG') }}
          </template>
        </component>
      </p>

      <dealer-cell-location
        class="dealer-item__cell"
        :location="item.location"
      />

      <p class="dealer-item__cell">
        <span class="dealers-cmn__str dealers-cmn__str_sec">
          {{ identityFeeLevel }}
        </span>
      </p>

      <dealer-cell-operations
        class="dealer-item__cell dealer-item__cell_end"
        :identity="item"
      />
    </div>
  </div>
</template>

<script>
import DealerCellNameAva from './DealerCellNameAva'
import DealerCellEmail from './DealerCellEmail'
import DealerCellLocation from './DealerCellLocation'
import DealerCellOperations from './DealerCellOperations'

import { mapGetters } from 'vuex'
import { extensionDealersGetters } from '../store/types'
import { FEE_LEVELS } from '../constants'

import { Identity } from 'Models/Identity'

export default {
  name: 'dealer-item',
  components: {
    DealerCellNameAva,
    DealerCellEmail,
    DealerCellLocation,
    DealerCellOperations,
  },

  props: {
    item: {
      type: Identity,
      required: true,
    },
  },

  computed: {
    ...mapGetters('ui/extension-dealers', {
      managedLogins: extensionDealersGetters.MANAGED_LOGINS,
      checkIsBlacklisted: extensionDealersGetters.CHECK_IS_BLACKLISTED,
    }),

    isBlocked () {
      return this.checkIsBlacklisted(this.item.id)
    },

    isAssigned () {
      return this.item.copartLogin &&
        this.item.copartLogin !== 'null' &&
        this.managedLogins.includes(this.item.copartLogin)
    },

    isAccountLinkShown () {
      return !this.isBlocked && this.isAssigned &&
        this.$can(this.$USER_CLAIMS.EXTENSION_ACCOUNTS)
    },

    feeLevelsMap () {
      return {
        [FEE_LEVELS.LOW]: this.$t('FEE_LEVEL_LOW'),
        [FEE_LEVELS.HIGH]: this.$t('FEE_LEVEL_HIGH'),
      }
    },

    identityFeeLevel () {
      return this.feeLevelsMap[this.item.feeLevel] ||
        this.item.feeLevel ||
        this.$t('COMMON.SYMBOLS.MDASH')
    },

    FEE_LEVELS: () => FEE_LEVELS,
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/dealers";

.dealer-item {
  padding: 2.1em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);

  &__cell {
    &_end {
      justify-self: end;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "BLOCKED_STATUS_MSG": "Blocked",
    "FEE_LEVEL_LOW": "LOW",
    "FEE_LEVEL_HIGH": "HIGH"
  },
  "ka": {
    "BLOCKED_STATUS_MSG": "დაბლოკილი",
    "FEE_LEVEL_LOW": "ᲓᲐᲑᲐᲚᲘ",
    "FEE_LEVEL_HIGH": "ᲛᲐᲦᲐᲚᲘ"
  },
  "ru": {
    "BLOCKED_STATUS_MSG": "Заблокирован",
    "FEE_LEVEL_LOW": "НИЗКАЯ",
    "FEE_LEVEL_HIGH": "ВЫСОКАЯ"
  },
  "uk": {
    "BLOCKED_STATUS_MSG": "Заблокований",
    "FEE_LEVEL_LOW": "НИЗЬКА",
    "FEE_LEVEL_HIGH": "ВИСОКА"
  }
}
</i18n>
